import enumEstate from 'enumEstate.json'
import querystring from 'query-string'
import { createRange, toArray } from 'helper/array'
import { forEach, map, sum } from 'lodash'
import { Finance } from 'financejs'
import Moment from 'moment'
import { EstateJSON } from './estate'
import { timestampToText } from 'helper/date'
import { buildingStructureToText } from 'helper/types'
import { separateComma } from 'helper/number'

export type SimulationPayload = {
  estate: EstateJSON
  params: SimulationParams
  result: SimulationResult
}

export type SimulationParams = {
  /** 賃料一定 */
  is_rent_fixed: boolean
  /** その他所得を含む */
  include_other_income: boolean
  /** 売却時期 */
  priorities_when_selling: number
  /** 売却時期の表面利回り */
  market_gross_rates: number
  /** 月額賃料 */
  months_rent: number
  /** 想定稼働率 */
  active_rates: number
  /** 購入価格 */
  selling_price: number
  /** 購入諸費用 */
  selling_expenses: number
  /** 自己資金額 */
  own_resources: number
  /** ローン */
  loan_usage_amount: number
  /** ローン利息金利 */
  borrowing_interest_rate: number
  /** ローン返却期間 */
  borrowing_period: number
  /** BM費 */
  bm: number
  /** 水道費などその他費用 */
  other_cost: number
  /** PM費 */
  pm_rate: number
  /** 固定資産税 */
  property_tax: number
  /** 土地割合 */
  land_ratio: number
  /** 建物割合 */
  building_ratio: number
  /** 本体割合 */
  body_ratio: number
  /** 本体耐用年数 */
  body_life_ratio: number
  /** 設備割合 */
  equip_ratio: number
  /** 設備耐用年数 */
  equip_life_ratio: number
  max_vacancy_rates: number[]
  min_vacancy_rates: number[]
}

export type SimulationResult = {
  /** 初期投資額 */
  initial_cost: number
  /** 土地価格 */
  land_price: number
  /** 建物価格 */
  building_price: number
  /** 満室想定年間賃料 [円] (GPI) */
  first_year_rent: number
  annual_rents: number[]
  /** 合計純賃料 */
  total_rent: number
  /** 空室想定年間賃料 [円] (EGI) */
  annual_effective_rent: number
  annual_effective_rents: number[]
  /** 空室損失 [円] */
  vacancy_loss_rent: number
  /** 運営費用 */
  annual_operating_expenses: number
  operating_expenses: number[]
  pm: number
  pms: number[]
  bms: number[]
  property_taxs: number[]
  other_costs: number[]
  pm_monthly: number
  /** NOI */
  noi: number[]
  /** IRR */
  irr: number
  /** インカム総計 */
  total_income: number
  /** 純インカム */
  pure_income: number
  /** 税引前キャッシュフロー Before Tax Cash Flow */
  btcf: number[]
  /** 税引後キャッシュフロー After Tax Cash Flow */
  atcf: number[]
  /** 推定売却価格 */
  estimated_selling_price_from: number
  estimated_selling_price_to: number
  estimated_selling_prices: number[]
  /** 購入時の推定利回り */
  market_gross_rate_buying: number
  /** 売却時の推定利回り */
  market_gross_rate_selling: number
  /** コメント */
  valuationLabel: string
  /** ローン返済額 */
  annual_loan_repayment_amounts: number[]
  /** ローン返済額（年間） */
  annual_loan_repayment_amount: number
  /** ローン元本返済額（年間） */
  annual_loan_usages: number[]
  /** ローン返済額（合計） */
  total_loan_repayment_amount: number
  /** ローン返済額（売却までの合計） */
  total_loan_repayment_amount_to_sold: number
  /** ローン総額（売却までの合計） */
  total_loan_usage_amount_to_sold: number
  /** ローン利息額 */
  borrowing_interest_amount: number[]
  /** ローン本体額 */
  borrowing_body_amount: number[]
  /** 現在から売却時までの各年度のローン残高配列（残り元本 + 利息） */
  calc_debts_arr: number[]
  /** 売却時のローン残高（残り元本 + 利息） */
  debts: number
  /** 現在から売却時までの各年度のローン残債配列（残り元本） 残債は利息を含まない。*/
  loan_balances: number[]
  /** 課税所得 */
  taxable_incomes: number[]
  /** 課税所得（相殺後） */
  taxable_incomes_result: number[]
  /** 納税額と納税率 */
  tax_amount: {
    price: number
    rate: number
  }[]
  other_income_tax: number
  /** 所得控除 */
  income_deduction: number[]
  /** デッドクロス（減価償却額（物件減価償却）が、ローンの元金返済額 - 利息 を下回りはじめた年） */
  dead_cross_year: number
  /** 課税所得がその他所得を上回る年 */
  taxable_income_over_other_year: number
  /** 税引き後キャッシュフローの値がその他所得（単独税引後）を上回る年 */
  atcf_over_other_income_year: number
  /** 過去に購入したかどうか */
  is_parchased: boolean
  /** 過去に精算したローン */
  total_parchased_loan: number
  /** 過去に精算したローン利息 */
  total_parchased_loan_interest: number
  /** 過去に精算した減価償却費 */
  total_parchased_deprication: number
  /** 購入年月から現在までの期間 */
  diff_parchased_to_now: number
  /** 中古本体耐用年数 */
  old_body_life_ratio: number
  /** 中古設備耐用年数 */
  old_equipment_life_ratio: number
  /** 減価償却費 */
  annual_depreciation_costs: number[]
  /** 減価償却期間 */
  depreciation_length: number
  /** 簿価 */
  book_values: number[]
  /** その他所得 */
  other_incomes: number[]
  /** 全期間における予想獲得賃料 */
  total_effective_rents: number
  /** 表面利回り */
  gross_rate: number
  /** NOI利回り */
  noi_rate: number
  printURL: string
  /** 純キャピタル */
  pureCapital: number
  /** 累積資産額 = 純利益(=純インカム + 純キャピタル) */
  final_asset: number
  /** csv出力用 */
  csvData: any[]
  /* 累積純営業利益配列 */
  accumulation_income: number[]
}

export const simulationDefaults = {
  priorities_when_selling: 15, // 売却時期
  borrowing_interest_rate: 2.5, // ローン比率
  borrowing_period: 15, // ローン返済期間
  other_cost: 0, // 水道費などその他費用
  pm_rate: 4, // PM費
  property_tax: 0, // 固定資産税
  land_ratio: 40, // 土地割合（初期値: 40）
  body_ratio: 80, // 本体割合（初期値: 80）
  body_life_ratio: 47, // 本体耐用年数
  equip_life_ratio: 15, // 設備耐用年数
}

export const getRentMap = (
  rents: number[],
  vacancy_rates: number[],
  active_rates: number
) =>
  rents.map((rent, i) => {
    const delta =
      (100 - active_rates) / 100 - Math.round(vacancy_rates[0] * 1000) / 1000
    return parseFloat(
      (
        (rent *
          12 *
          (1 - (Math.round(vacancy_rates[i] * 1000) / 1000 + delta))) /
        10000
      ).toFixed(1)
    )
  })

export const mergeSimulationParams = (
  estate: EstateJSON,
  params?: SimulationParams,
  before?: SimulationParams
): SimulationParams => {
  const is_rent_fixed = params ? params.is_rent_fixed : false
  const include_other_income = params ? params.include_other_income : true

  const selling_price = merge(
    estate.expected_selling_price,
    params && params.selling_price
  )
  const selling_expenses = Math.floor(
    merge(selling_price * 0.05, params && params.selling_expenses)
  )

  const initial_cost = selling_price + selling_expenses

  const own_resources = Math.floor(
    merge(selling_price * 0.15, params && params.own_resources)
  )

  const loan_usage_amount = Math.floor(
    merge(
      initial_cost - selling_price * 0.15,
      params && params.loan_usage_amount
    )
  )

  const d = simulationDefaults

  const land_ratio = merge(d.land_ratio, params && params.land_ratio)

  const building_ratio = merge(
    100 - land_ratio,
    params && params.building_ratio
  )

  const body_ratio = merge(d.body_ratio, params && params.body_ratio)

  const priorities_when_selling = merge(
    d.priorities_when_selling,
    params && params.priorities_when_selling === 0
      ? 1
      : params && Math.ceil(params.priorities_when_selling),
    estate.priorities_when_selling
  )

  const priorities_when_selling_changed =
    before && before.priorities_when_selling !== priorities_when_selling

  const market_gross_rates = merge(
    Math.round(estate.market_gross_rates[priorities_when_selling] * 100) / 100,
    !priorities_when_selling_changed &&
      params &&
      Math.round(params.market_gross_rates * 100) / 100
  )

  const months_rent = merge(estate.months_rent, params && params.months_rent)

  const active_rates = merge(
    parseFloat(
      ((1 - Math.round(estate.vacancy_rates[0] * 1000) / 1000) * 100).toFixed(1)
    ),
    params && params.active_rates
  )

  const borrowing_interest_rate = merge(
    d.borrowing_interest_rate,
    params && params.borrowing_interest_rate
  )
  const borrowing_period = Math.floor(
    merge(d.borrowing_period, params && params.borrowing_period)
  )

  const bm = Math.floor(merge(0, params && params.bm))
  const other_cost = Math.floor(
    merge(d.other_cost, params && params.other_cost)
  )
  const pm_rate = merge(d.pm_rate, params && params.pm_rate)
  const property_tax = Math.floor(
    merge(d.property_tax, params && params.property_tax)
  )

  // Inputに値が入ってなかったら、構造によってデフォルトの法定耐用年数を入れる
  let body_life_ratio = merge(
    d.body_life_ratio,
    params && params.body_life_ratio
  )

  if (params === undefined) {
    const titles = enumEstate.enum.BuildingStructure.titles_dict
    const needle = estate.building_structure.toString() as keyof typeof titles
    switch (titles[needle]) {
      case 'SRC造':
      case 'RC造':
        body_life_ratio = 47
        break
      case '木造':
        body_life_ratio = 22
        break
      case '鉄骨造':
        body_life_ratio = 34
        break
      case 'その他':
      default:
        body_life_ratio = 0
    }
  }

  const equip_ratio = merge(100 - body_ratio, params && params.equip_ratio)
  const equip_life_ratio = merge(
    d.equip_life_ratio,
    params && params.equip_life_ratio
  )

  const max_vacancy_rates = estate.max_vacancy_rates
  const min_vacancy_rates = estate.min_vacancy_rates

  return {
    is_rent_fixed,
    include_other_income,
    priorities_when_selling,
    market_gross_rates,
    months_rent,
    active_rates,
    selling_price,
    selling_expenses,
    own_resources,
    loan_usage_amount,
    borrowing_interest_rate,
    borrowing_period,
    bm,
    other_cost,
    pm_rate,
    property_tax,
    land_ratio,
    building_ratio,
    body_ratio,
    body_life_ratio,
    equip_ratio,
    equip_life_ratio,
    max_vacancy_rates,
    min_vacancy_rates,
  }
}

export const runSimulation = (
  estate: EstateJSON,
  params: SimulationParams
): SimulationResult => {
  const {
    is_rent_fixed,
    priorities_when_selling,
    // market_gross_rates,
    // months_rent,
    // active_rates,
    selling_price,
    selling_expenses,
    // own_resources,
    loan_usage_amount,
    borrowing_interest_rate,
    borrowing_period,
    bm,
    other_cost,
    pm_rate,
    property_tax,
    // land_ratio,
    body_life_ratio,
    equip_life_ratio,
    // max_vacancy_rates,
    // min_vacancy_rates
  } = params

  const TYPE_PERSONAL = 1
  const TYPE_COMPANY = 2
  const estate_purchased_date = (estate.purchased_date.$date ||
    estate.purchased_date) as number
  const estate_built_date = (estate.built_date.$date ||
    estate.built_date) as number

  const finance = new Finance()

  // 各種計算用: 投資期間と同じ長さの配列・購入費用（単位: 円）・建物割合（単位: 小数）
  const _calcPlanPeriods = estate.market_gross_rates

  // TRUE: 過去に購入済み かつ 取引タイプが購入ではない
  const is_trade_buy = estate.trade_type === 1
  const is_parchased = is_trade_buy
    ? false
    : Moment(estate_purchased_date).year() < Moment().year()
  // 購入した時点から現在までの期間
  const diff_parchased_to_now = Moment().diff(
    Moment(estate_purchased_date),
    'years'
  )

  // 初年度期間
  const firstYearTerm = () => {
    let term = 1
    if (estate.personal_company_type === TYPE_PERSONAL) {
      term = 12 - Moment(estate_purchased_date).month()
      return term ? term : 1
    }
    // 決算月のほうが時系列的に後
    if (estate.final_account_month >= Moment(estate_purchased_date).month()) {
      term = estate.final_account_month - Moment(estate_purchased_date).month()
    } else {
      term =
        12 - Moment(estate_purchased_date).month() + estate.final_account_month
    }
    return term ? term : 1
  }

  // 初年度期間影響調整
  const firstYearTermRate = is_parchased ? 1 : firstYearTerm() / 12

  const _total_by_sold = (
    list: number[],
    sold: number = params.priorities_when_selling
  ) => {
    return sum(
      map(list, (value, index) => {
        if (index < sold) {
          return value
        }
      })
    )
  }

  // 各年度のその他所得
  const other_incomes = map(_calcPlanPeriods, (v, index) => {
    if (estate.personal_company_type === TYPE_COMPANY) {
      return 0
    }

    if (!params.include_other_income) {
      return 0
    }

    return estate.other_income * 10000
  })

  // 元利均等返済年間返済額算出
  const monthly_loan_repayment_amount = (() => {
    const r = borrowing_interest_rate / 1200 // 年利を月利に変換。同時に1/100に。
    const paybackTimes = borrowing_period * 12 // 返済回数(毎月返済)
    // 返済額算出
    let paybackCost =
      (loan_usage_amount * r * Math.pow(1 + r, paybackTimes)) /
      (Math.pow(1 + r, paybackTimes) - 1)
    return paybackCost
  })()

  const annual_loan_repayment_amount = Math.round(
    monthly_loan_repayment_amount * 12 * 10000
  )

  // 各年度のローン返済額
  const annual_loan_repayment_amounts = map(_calcPlanPeriods, (v, index) => {
    if (index === 0) {
      return Math.round(annual_loan_repayment_amount * firstYearTermRate)
    }

    if (index < params.borrowing_period) {
      return annual_loan_repayment_amount
    }

    if (index === params.borrowing_period) {
      return Math.round(annual_loan_repayment_amount * (1 - firstYearTermRate))
    }

    return 0
  })

  const _clone_annual_loan_repayment_amounts = map(
    annual_loan_repayment_amounts,
    (val) => {
      return val
    }
  )

  let total_parchased_loan = 0

  // 過去に購入済みの場合、ローン返済を現在までの期間と照らし合わせて返却終わったことにする
  if (is_parchased) {
    forEach(toArray(0, diff_parchased_to_now), (value, index) => {
      total_parchased_loan += annual_loan_repayment_amounts[0]
      annual_loan_repayment_amounts.shift()
      annual_loan_repayment_amounts.push(0)
    })
  }

  // ローン返済総額
  const total_loan_repayment_amount = (() => {
    let total_value = total_parchased_loan
    forEach(annual_loan_repayment_amounts, (value) => {
      total_value += value
    })
    return total_value
  })()

  // 利息返済計算
  const calcInterestRepayment = () => {
    // 年ごとの利息返済額を元利均等返済に基づいて算出
    const r = borrowing_interest_rate / 100 / 12 // 年利を月利に変換。単位を%から少数へ。
    const paybackTimes = borrowing_period * 12 // 返済回数(毎月返済)

    // 月ごとの利息返済額を元利均等返済に基づいて算出
    const _calcMonthlyInterestRepayments = () => {
      // 月ごとの元本返済額
      let left_repayments = loan_usage_amount * 10000
      // 月ごとの利息返済額
      const monthly_interest_repayments: number[] = []

      forEach(toArray(1, paybackTimes), (value, index) => {
        const interest_repayment = Math.round(left_repayments * r)
        left_repayments -=
          monthly_loan_repayment_amount * 10000 - interest_repayment
        monthly_interest_repayments.push(interest_repayment)
      })
      return monthly_interest_repayments
    }

    let year = 0
    let month = 0
    // 年ごとの利息返済額
    const annual_interest_repayments = toArray(0, _calcPlanPeriods.length)
    forEach(_calcMonthlyInterestRepayments(), (monthlyIR, index) => {
      annual_interest_repayments[year] += monthlyIR
      month += 1
      if (year === 0 && !is_parchased) {
        if (month === firstYearTerm()) {
          month = 0
          year += 1
        }
      }
      if (month === 12) {
        month = 0
        year += 1
      }
    })

    return annual_interest_repayments
  }

  // ローン利息額: return Array
  const borrowing_interest_amount = calcInterestRepayment()
  const _clone_borrowing_interest_amount = map(
    borrowing_interest_amount,
    (val) => {
      return val
    }
  )

  // ローン本体額
  const borrowing_body_amount = annual_loan_repayment_amounts.map(
    (loan, index) => loan - borrowing_interest_amount[index]
  )

  // ローン返済額（売却までの合計）
  const total_loan_repayment_amount_to_sold = _total_by_sold(
    annual_loan_repayment_amounts
  )

  // 売却までのローン総額
  const total_loan_repayment_amount_to_solds = toArray(
    0,
    params.priorities_when_selling
  ).map((_, index) => {
    return _total_by_sold(annual_loan_repayment_amounts, index + 1)
  })

  // 年間ローン元本返済額: return Array
  const annual_loan_usages = map(
    _clone_annual_loan_repayment_amounts,
    (loan_repayment, index) => {
      return (
        loan_repayment - Math.round(_clone_borrowing_interest_amount[index])
      )
    }
  )

  // ローン残債（残り元本）
  // TODO: 購入月計算
  let total_loan_balance = loan_usage_amount * 10000
  const loan_balances = map(annual_loan_usages, (loan_usage, index) => {
    total_loan_balance -= loan_usage
    // 誤差丸め
    if (total_loan_balance < 12) {
      total_loan_balance = 0
    }
    return total_loan_balance
  })

  // 過去に購入済みの場合、ローン元本を現在までの期間と照らし合わせてずらす
  if (is_parchased) {
    forEach(toArray(0, diff_parchased_to_now), () => {
      loan_balances.shift()
      loan_balances.push(0)
    })
  }

  // 過去に購入済みの場合、ローン利息を現在までの期間と照らし合わせてずらす
  let total_parchased_loan_interest = 0
  if (is_parchased) {
    forEach(toArray(0, diff_parchased_to_now), (value, index) => {
      total_parchased_loan_interest += borrowing_interest_amount[0]
      borrowing_interest_amount.shift()
      borrowing_interest_amount.push(0)
    })
  }

  // ローン残高: return Array
  // 各年度のローン残高 = ローン返済総額 - 既存の支払総額 - 売却までの返済総額
  const calc_debts_arr = toArray(0, params.priorities_when_selling).map(
    (_, index) => {
      return (
        total_loan_repayment_amount -
        total_parchased_loan -
        total_loan_repayment_amount_to_solds[index]
      )
    }
  )

  // 売却時ローン残高
  const calc_debts = calc_debts_arr[params.priorities_when_selling - 1]
  const debts = calc_debts > 0 ? calc_debts : 0

  // 売却までに必要なローン総額
  const _total_loan_usage_amount_to_sold = (() => {
    let total = toArray(0, 1)
    // 売却期間のほうが借入期間よりも長い場合
    if (params.borrowing_period <= params.priorities_when_selling) {
      // ローン利用額 / 借入期間 (全期間同じ金額)
      total = toArray(
        (params.loan_usage_amount * 10000) / params.borrowing_period,
        params.borrowing_period
      )
      // 借入期間の方が売却期間よりも長い
    } else {
      total = toArray(
        (params.loan_usage_amount * 10000) / params.borrowing_period,
        params.borrowing_period
      )
      //
      try {
        total[0] *= firstYearTermRate
        total.push(total[1] - total[0])
      } catch (e) {
        console.log(e)
      }
    }
    return total
  })()

  // ローン総額（売却までの合計）
  const total_loan_usage_amount_to_sold = Math.round(
    _total_by_sold(_total_loan_usage_amount_to_sold)
  )

  const initial_cost = selling_price + selling_expenses
  const land_price = selling_price * (params.land_ratio / 100)
  const building_price = initial_cost * (params.building_ratio / 100)

  const annual_rents = estate.rents.map((rent, index) => {
    // 下落率
    const rent_rate = (params.months_rent * 10000) / estate.rents[0]

    let annual_rent = 0

    if (is_rent_fixed) {
      annual_rent = estate.rents[0] * 12 * rent_rate
    } else if (index % 2 === 0) {
      annual_rent = estate.rents[index] * 12 * rent_rate
    } else {
      annual_rent = estate.rents[index - 1] * 12 * rent_rate
    }

    if (index === 0) {
      return Math.round(annual_rent * firstYearTermRate)
    }

    return Math.round(annual_rent)
  })

  const first_year_rent = annual_rents[1]

  // 想定稼働年間収入
  const annual_effective_rent =
    first_year_rent * (1 - Math.round(estate.vacancy_rates[0] * 1000) / 1000)
  const annual_effective_rents = annual_rents.map((rent, i) => {
    return Math.round((annual_rents[i] * params.active_rates) / 100)
  })

  const vacancy_loss_rent = sum(
    annual_rents.map((rent, i) => {
      if (i >= params.priorities_when_selling) {
        return 0
      }
      return Math.round((annual_rents[i] * (100 - params.active_rates)) / 100)
    })
  )

  const pm = Math.round(annual_effective_rents[1] * pm_rate * 0.01)

  const pms = toArray(pm, params.priorities_when_selling)
  pms[0] *= firstYearTermRate
  pms[0] = Math.round(pms[0])
  const bms = toArray(params.bm * 12, params.priorities_when_selling)
  bms[0] *= firstYearTermRate
  bms[0] = Math.round(bms[0])

  const pm_monthly = Math.round(pm / 12)
  const property_taxs = toArray(
    params.property_tax,
    params.priorities_when_selling
  )
  property_taxs[0] *= firstYearTermRate
  property_taxs[0] = Math.round(property_taxs[0])
  const other_costs = toArray(
    params.other_cost * 12,
    params.priorities_when_selling
  )
  other_costs[0] *= firstYearTermRate
  other_costs[0] = Math.round(other_costs[0])

  // 年間運営費
  const annual_operating_expenses = Math.round(
    bm * 12 + property_tax + pm + other_cost * 12
  )

  const operating_expenses = toArray(
    annual_operating_expenses,
    params.priorities_when_selling
  )
  operating_expenses[0] *= firstYearTermRate
  operating_expenses[0] = Math.round(operating_expenses[0])

  const noi = annual_effective_rents.map((value, index) => {
    if (index === 0) {
      return Math.round(value - annual_operating_expenses * firstYearTermRate)
    }
    return Math.round(value - annual_operating_expenses)
  })

  const total_rent = _total_by_sold(annual_rents)

  // インカム合計
  const total_income = total_rent - vacancy_loss_rent - sum(operating_expenses)
  const pure_income =
    total_income + (-total_loan_repayment_amount + total_parchased_loan + debts)

  const btcf = noi.map(
    (value, index) =>
      value + other_incomes[index] - annual_loan_repayment_amounts[index]
  )

  const calcTaxPriceAndRate = (prices: number[]) => {
    const price_tax = prices.map((value, index) => {
      if (value <= 0) {
        return {
          rate: 0,
          price: 0,
        }
      }
      if (estate.personal_company_type === TYPE_PERSONAL) {
        if (40000000 < value) {
          return {
            rate: 0.55,
            price: Math.round(value * 0.55) - 4796000,
          }
        } else if (18000000 <= value) {
          return {
            rate: 0.5,
            price: Math.round(value * 0.5) - 2796000,
          }
        } else if (9000000 <= value) {
          return {
            rate: 0.43,
            price: Math.round(value * 0.43) - 1536000,
          }
        } else if (6950000 <= value) {
          return {
            rate: 0.33,
            price: Math.round(value * 0.33) - 636000,
          }
        } else if (3300000 <= value) {
          return {
            rate: 0.3,
            price: Math.round(value * 0.3) - 427500,
          }
        } else if (1950000 <= value) {
          return {
            rate: 0.2,
            price: Math.round(value * 0.2) - 97500,
          }
        } else {
          return {
            rate: 0.15,
            price: Math.round(value * 0.15),
          }
        }
      } else {
        return {
          rate: 0.3,
          price: Math.round(value * 0.3),
        }
      }
    })
    return price_tax
  }

  const other_income_tax =
    other_incomes[0] -
    calcTaxPriceAndRate(
      toArray(other_incomes[0], params.priorities_when_selling)
    )[0].price

  const old_body_life_ratio = (() => {
    let _calcYears = 0

    // 過去に購入の場合
    if (is_parchased) {
      _calcYears = Moment(estate_purchased_date).diff(
        Moment(estate_built_date),
        'years'
      )
    } else {
      _calcYears = estate.age_of_building
    }

    // 法定耐用年数 <= 築年数 耐用年数*0.2 小数切り捨て、整数
    // 法定耐用年数 > 築年数 (耐用年数 - 築年数) + 築年数*0.2 小数切り捨て、整数
    if (body_life_ratio <= _calcYears) {
      return Math.round(body_life_ratio * 0.2)
    } else {
      return Math.round(body_life_ratio - _calcYears + _calcYears * 0.2)
    }
  })()

  const old_equipment_life_ratio = (() => {
    let _calcYears = 0

    // 過去に購入の場合
    if (is_parchased) {
      _calcYears = Moment(estate_purchased_date).diff(
        Moment(estate_built_date),
        'years'
      )
    } else {
      _calcYears = estate.age_of_building
    }

    if (equip_life_ratio <= _calcYears) {
      return Math.round(equip_life_ratio * 0.2)
    } else {
      return Math.round(equip_life_ratio - _calcYears + _calcYears * 0.2)
    }
  })()

  // 本体の耐用年数期間の減価償却費配列
  const _calc_most_old_years =
    (old_body_life_ratio > old_equipment_life_ratio
      ? old_body_life_ratio
      : old_equipment_life_ratio) + 1
  const _annual_building_depreciation_costs = map(
    toArray(0, _calc_most_old_years),
    (n, index) => {
      const depreciation =
        (building_price * 10000 * (params.body_ratio / 100)) /
        old_body_life_ratio

      // 初年度
      if (index === 0) {
        return Math.round(depreciation * firstYearTermRate)
      }

      if (index < old_body_life_ratio) {
        // 購入費用 * 建物割合 * 本体割合 / 本体耐用年数 [本体耐用年数]
        return Math.round(depreciation)
      }

      // 初年度ではみ出た分の調整
      if (index === old_body_life_ratio) {
        return Math.round(depreciation * (1 - firstYearTermRate))
      }

      return 0
    }
  )

  // 設備の耐用年数期間の減価償却費配列
  const _annual_equipment_depreciation_costs = map(
    toArray(0, _calc_most_old_years),
    (n, index) => {
      const depreciation =
        (building_price * 10000 * (params.equip_ratio / 100)) /
        old_equipment_life_ratio

      // 初年度
      if (index === 0) {
        return Math.round(depreciation * firstYearTermRate)
      }

      if (index < old_equipment_life_ratio) {
        // 購入費用 * 建物割合 * 設備割合 / 設備耐用年数
        return Math.round(depreciation)
      }

      // 初年度ではみ出た分の調整
      if (index === old_equipment_life_ratio) {
        return Math.round(depreciation * (1 - firstYearTermRate))
      }

      return 0
    }
  )

  // 各年度の減価償却費
  // 1年目: 建物本体減価償却費 + 建物設備減価償却費 + 購入諸費用の50%
  // 以降: 建物本体減価償却費 + 建物設備減価償却費
  const annual_depreciation_costs = map(
    _annual_building_depreciation_costs,
    (building_cost, index) => {
      return building_cost + _annual_equipment_depreciation_costs[index]
    }
  )

  // 過去購入時に減価償却費を削る
  let total_parchased_deprication = 0
  if (is_parchased) {
    forEach(toArray(0, diff_parchased_to_now), (value, index) => {
      total_parchased_deprication += annual_depreciation_costs[0]
      annual_depreciation_costs.shift()
      annual_depreciation_costs.push(0)
    })
  }
  forEach(estate.rents, (_, index) => {
    annual_depreciation_costs[index] = annual_depreciation_costs[index]
      ? annual_depreciation_costs[index]
      : 0
  })

  // 減価償却期間
  const depreciation_length = annual_depreciation_costs.filter(
    (value) => value > 0
  ).length

  // 各年度時点の帳簿価格
  let _calcTotalDepreciation = 0
  const book_values = map(annual_depreciation_costs, (depreciation, index) => {
    _calcTotalDepreciation += depreciation
    // 設定価格 - 特定年度までの減価償却費合計値 - すでに支払った減価償却費
    let book_value =
      selling_price * 10000 +
      (selling_expenses * 10000 * params.building_ratio) / 100 -
      _calcTotalDepreciation -
      total_parchased_deprication
    // 誤差丸め
    if (Math.round(book_value / 10000) === land_price) {
      book_value = land_price * 10000
    }
    return book_value
  })

  let _memo_book_value = 0
  forEach(_calcPlanPeriods, (value, index) => {
    if (index < book_values.length) {
      _memo_book_value = book_values[index]
      return
    }
    book_values.push(_memo_book_value)
  })

  const taxable_incomes = map(
    borrowing_interest_amount,
    (annual_interest_amount, index) => {
      return (
        noi[index] +
        other_incomes[index] -
        annual_interest_amount -
        annual_depreciation_costs[index]
      )
    }
  )

  // 課税所得に相殺の計算をする
  // 課税所得を相殺の計算用の変数にトレースする

  // プラスのみ相殺して修正された値を表示用の変数へ入れる
  let taxable_incomes_result: number[] = []
  let taxable_incomes_offset = [...taxable_incomes]
  taxable_incomes_offset.forEach((taxable_income, value_year) => {
    if (taxable_income > 0) {
      // 個人の場合は３年前から、それ以外は10年前からマイナスと相殺する
      const _INCOMES_OFFSET_NUM = estate.personal_company_type === 1 ? 3 : 10
      // 現在の年数-1と、相殺年数を比較して、小さい方を相殺する年数に使用する。
      const _INCOMES_OFFSET_LENGTH =
        _INCOMES_OFFSET_NUM < value_year ? _INCOMES_OFFSET_NUM : value_year
      const _REVERSE_YEARS = createRange(1, _INCOMES_OFFSET_LENGTH).reverse()
      // 過去のマイナスから相殺する
      for (let income_year_index in _REVERSE_YEARS) {
        const income_year = _REVERSE_YEARS[income_year_index]
        // マイナスの場合
        if (taxable_incomes_offset[value_year - income_year] < 0) {
          // マイナスの方が絶対値が大きい場合、プラスを全て相殺する。
          if (
            -taxable_incomes_offset[value_year - income_year] >= taxable_income
          ) {
            taxable_incomes_offset[value_year - income_year] =
              taxable_incomes_offset[value_year - income_year] + taxable_income
            taxable_income = 0
            taxable_incomes_offset[value_year] = 0
          } else {
            taxable_income =
              taxable_income + taxable_incomes_offset[value_year - income_year]
            taxable_incomes_offset[value_year - income_year] = 0
            taxable_incomes_offset[value_year] = taxable_income
          }
        }
      }
    }
    taxable_incomes_result.push(taxable_income)
  })

  const tax_amount = calcTaxPriceAndRate(taxable_incomes_result)

  const atcf = btcf.map((value, index) =>
    Math.round(value - tax_amount[index].price)
  )

  // 減価償却額（物件減価償却）が、ローンの元金返済額 - 利息 を下回りはじめた年をデッドクロスと定義
  const dead_cross_first_year = annual_depreciation_costs
    .map((value, index) => ({
      year: index + 1,
      is_dead_cross:
        value <
        annual_loan_repayment_amounts[index] - borrowing_interest_amount[index]
          ? true
          : false,
    }))
    .filter((year) => year.is_dead_cross)[0]

  const dead_cross_year = dead_cross_first_year ? dead_cross_first_year.year : 0

  // 課税所得がその他所得を上回る年を計算
  const taxable_income_over_other_first_year =
    estate.personal_company_type === TYPE_COMPANY || other_incomes[0] === 0
      ? 0
      : taxable_incomes_result
          .map((value, index) => {
            return {
              year: index + 1,
              is_tax_over_other_income: value > other_incomes[0] ? true : false,
            }
          })
          .filter((year) => year.is_tax_over_other_income)[0]
  const taxable_income_over_other_year = taxable_income_over_other_first_year
    ? taxable_income_over_other_first_year.year
    : 0

  // 税引き後キャッシュフローの値がその他所得（単独税引後）を上回る年を計算
  const atcf_over_other_income = atcf.filter(
    (value) => value > other_income_tax
  ).length // 上回る年の数
  let atcf_over_other_income_year = 0
  forEach(atcf, (value, index) => {
    if (value < other_income_tax) {
      if (index === 0) {
        if (atcf_over_other_income === 0) {
          atcf_over_other_income_year = index + 1
          return false
        }
      } else {
        atcf_over_other_income_year = index + 1
        return false
      }
    }
  })
  atcf_over_other_income_year =
    estate.personal_company_type === TYPE_COMPANY || other_incomes[0] === 0
      ? 0
      : atcf_over_other_income_year

  // 各年度の所得控除
  const income_deduction = map(_calcPlanPeriods, (interest, index) => {
    let calcValue = 0
    calcValue +=
      annual_depreciation_costs[index] + borrowing_interest_amount[index]
    return calcValue || 0
  })

  const total_effective_rents = Math.round(sum(annual_effective_rents) / 10000)

  // 表面利回り - キャッシュフロー表
  const gross_rate = (annual_rents[1] / (selling_price * 10000)) * 100

  // NOI利回り - キャッシュフロー表
  const noi_rate =
    ((annual_effective_rent - annual_operating_expenses) /
      (selling_price * 10000)) *
    100

  // 下落率（1年目を1とする）
  const decrease_rent_rates = map(annual_rents, (annual_rent, index) => {
    if (index === 0) {
      return 1
    }
    return annual_rent / first_year_rent
  })

  // 売却価格（上限値・下限値）
  const estimated_selling_prices = map(estate.sell_prices, (value, index) => {
    let price =
      (first_year_rent / estate.market_gross_rates[index] / 100) * 10000
    if (
      estate.market_gross_rates[params.priorities_when_selling] !==
      params.market_gross_rates
    ) {
      price = (first_year_rent / params.market_gross_rates / 100) * 10000
    }
    return Math.round(price * decrease_rent_rates[index])
  })

  const irr = (() => {
    let _calcIRR = []
    let _last_value = 0
    forEach(btcf, (value, index) => {
      if (index < params.priorities_when_selling) {
        _last_value = value - other_incomes[0]
        _calcIRR.push(_last_value)
      }
    })
    const _last_yaer = _calcIRR.length - 1
    _calcIRR[_last_yaer] =
      _last_value +
      (estimated_selling_prices[params.priorities_when_selling - 1] -
        loan_balances[params.priorities_when_selling - 1])

    let _own_cost = params.own_resources * 10000 * -1
    let _irr = 0
    try {
      _irr = finance.IRR(_own_cost, ..._calcIRR)
    } catch (e) {
      console.log(e)
    }
    return _irr
  })()

  // 購入時・売却時の市場推定利回り
  const market_gross_rate_buying =
    Math.round(estate.market_gross_rates[0] * 100) / 100
  const market_gross_rate_selling =
    estate.market_gross_rates[priorities_when_selling - 1]

  // 売却時の推定表面利回り
  params.market_gross_rates =
    market_gross_rate_selling !== params.market_gross_rates
      ? params.market_gross_rates
      : market_gross_rate_selling

  const pureCapital =
    estimated_selling_prices[params.priorities_when_selling - 1] -
    loan_balances[params.priorities_when_selling - 1] -
    params.own_resources * 10000

  const estimated_selling_price_from =
    estimated_selling_prices[priorities_when_selling - 1] * 1.05
  const estimated_selling_price_to =
    estimated_selling_prices[priorities_when_selling - 1] * 0.95

  const final_asset = pure_income + pureCapital

  // コメント
  const grossRateBetween =
    (params.market_gross_rates - market_gross_rate_selling) /
    (params.market_gross_rates * 0.1)

  const valuationLabel =
    grossRateBetween > 2.5
      ? '非常に悲観的'
      : grossRateBetween > 1.25
      ? '悲観的'
      : grossRateBetween > 0.5
      ? '少し悲観的'
      : grossRateBetween < -2.5
      ? '非常に楽観的'
      : grossRateBetween < -1.25
      ? '楽観的'
      : grossRateBetween < -0.5
      ? '少し楽観的'
      : '普通'

  const printPrefix = () => {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'stg-app.value-ai.jp'
    ) {
      return 'http://stg.value-ai.jp'
    }
    return 'http://value-ai.jp'
  }

  const printURL =
    `${printPrefix()}/planner/${
      estate._id
    }?estate_class_name=PrivateEstate#print&` +
    querystring.stringify({
      first_rent: params.months_rent,
      first_vacancy_rate: 100 - params.active_rates,
      sold_year: params.priorities_when_selling,
      sold_year_caprate: params.market_gross_rates,
      purchase_price: params.selling_price,
      initial_expense: params.selling_expenses,
      personal_funds: params.own_resources,
      total_loan_repayment: params.loan_usage_amount,
      interest_rate: params.borrowing_interest_rate,
      repayment_period: params.borrowing_period,
      depreciation_land_rate: params.land_ratio,
      depreciation_main_body_rate: params.body_ratio,
      statutory_durable_years_body: params.body_life_ratio,
      statutory_durable_years_equipment: params.equip_life_ratio,
      rents_prediction_chart_focus_year: 10,
      initial_months_management_fee: bm,
      initial_months_repair_reserve_fund_fee: 0,
      egi_rate_for_management_commission_expense_percentage: params.pm_rate,
      initial_other_management_fees: params.other_cost,
      initial_annual_property_tax: params.property_tax,
      initial_other_management_fee: params.other_cost,
      rent_certain_is: params.is_rent_fixed,
      cashflow_include_other_income_is: params.include_other_income,
    })

  // csvデータの列数
  const csvLength = params.priorities_when_selling
  // csvの列数、最低10
  const csvmaxLength = csvLength + 1 >= 10 ? csvLength + 1 : 10

  // csv出力用
  const csvArray = [
    ['物件ID', estate._id, '物件名', estate.estate_name],
    ['投資条件設定変更'],
    ['物件', '', '初期費用', '', 'ローン', '', '売却', '', '利回り'],
    [
      '所在地',
      estate.address,
      '物件購入額',
      `${separateComma(params.selling_price)}万円`,
      'ローン額',
      `${separateComma(params.loan_usage_amount)}万円`,
      '売却想定時期',
      `${params.priorities_when_selling}年後`,
      '表面利回り',
      `${gross_rate.toFixed(2)}%`,
    ],
    [
      '部屋番号',
      estate.room_number,
      '購入諸費用',
      `${separateComma(params.selling_expenses)}万円`,
      '自己資金',
      `${separateComma(params.own_resources)}万円`,
      'AI査定価格',
      `${separateComma(
        Math.round(
          estimated_selling_prices[params.priorities_when_selling - 1] / 10000
        )
      )}万円`,
      'NOI利回り',
      `${noi_rate.toFixed(2)}%`,
    ],
    [
      '最寄駅',
      `${estate.railways[0].station_name}駅 徒歩${estate.railways[0].walking_minutes}分`,
      '総額',
      `${separateComma(initial_cost)}万円`,
      '負債比率',
      `${separateComma(
        Math.round((1 - params.own_resources / params.loan_usage_amount) * 100)
      )}%`,
      '売却時ローン残債',
      `${separateComma(
        Math.round(loan_balances[priorities_when_selling - 1] / 10000)
      )}万円`,
      '全期間利回り（IRR)',
      `${irr && !is_parchased ? irr + '%' : '-'}`,
    ],
    [
      estate.property_type === 1 ? '専有面積' : '延床面積',
      estate.property_type === 1
        ? `${Math.round(Number(estate.occupation_area))}㎡`
        : `${Math.round(Number(estate.total_floor_space))}㎡`,
      '',
      '',
      '借入金利',
      `${separateComma(params.borrowing_interest_rate)}%`,
      '',
      '',
      '',
    ],
    [
      '築年月',
      timestampToText(estate_built_date),
      '',
      '',
      '借入期間',
      `${separateComma(params.borrowing_period)}年`,
      '',
      '',
      '',
    ],
    [
      '建物構造',
      `${buildingStructureToText(estate.building_structure)}造`,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],

    ['キャッシュフロー表'],
    [''].concat(toArray(0, csvLength).map((_, index) => String(index + 1))),
    ['満室想定年間賃料'].concat(
      annual_rents.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['空室想定年間賃料'].concat(
      annual_effective_rents
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['運営費用'].concat(
      operating_expenses.map((value: number) => String(value))
    ),
    ['うち年間BM費'].concat(bms.map((value: number) => String(value))),
    ['うち固定資産税・都市計画税'].concat(
      property_taxs.map((value: number) => String(value))
    ),
    ['うち年間PM費'].concat(pms.map((value: number) => String(value))),
    ['その他費用'].concat(other_costs.map((value: number) => String(value))),
    ['購入諸費用'].concat([String(params.selling_expenses * 10000)]),
    ['純営業利益(NOI)'].concat(
      noi.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['その他所得'].concat(
      other_incomes.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['ローン返済額(年間)'].concat(
      annual_loan_repayment_amounts
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['税引前キャッシュフロー'].concat(
      btcf.slice(0, csvLength).map((value: number) => String(value))
    ),
    [''],
    ['純営業利益(NOI)'].concat(
      noi.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['その他所得'].concat(
      other_incomes.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['減価償却費'].concat(
      annual_depreciation_costs
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['費用計上可能な利息額'].concat(
      borrowing_interest_amount
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['課税所得'].concat(
      taxable_incomes_result
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    [''],
    ['税引前キャッシュフロー'].concat(
      btcf.slice(0, csvLength).map((value: number) => String(value))
    ),
    ['納税額'].concat(
      tax_amount
        .map((tax) => tax.price)
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['税引後キャッシュフロー'].concat(
      atcf.slice(0, csvLength).map((value: number) => String(value))
    ),
    [''],
    ['AI査定価格'].concat(
      estimated_selling_prices
        .slice(0, csvLength)
        .map((value: number) => String(value))
    ),
    ['ローン残債'].concat(
      loan_balances
        .slice(0, csvLength)
        .map((value: number) => `-${String(value)}`)
    ),
  ]

  // 列の数をデータ数+1に合わせて、データがない部分には空白を入れる
  const csvData = csvArray.map((row: any[]) =>
    toArray(0, csvmaxLength).map((_, index) => (row[index] ? row[index] : ''))
  )

  // 累積NOI配列
  const accumulation_income = noi.map((_, i) => {
    return noi.slice(0, i).reduce((a: number, b: number) => a + b, 0)
  })

  return {
    initial_cost,
    land_price,
    building_price,
    first_year_rent,
    annual_rents,
    annual_effective_rent,
    annual_effective_rents,
    vacancy_loss_rent,
    annual_operating_expenses,
    operating_expenses,
    pm,
    pms,
    bms,
    pm_monthly,
    property_taxs,
    other_costs,
    noi,
    irr,
    total_rent,
    total_income,
    pure_income,
    btcf,
    atcf,
    estimated_selling_price_from,
    estimated_selling_price_to,
    estimated_selling_prices,
    market_gross_rate_buying,
    market_gross_rate_selling,
    valuationLabel,
    annual_loan_repayment_amounts,
    annual_loan_repayment_amount,
    annual_loan_usages,
    total_loan_repayment_amount,
    total_loan_repayment_amount_to_sold,
    total_loan_usage_amount_to_sold,
    borrowing_interest_amount,
    borrowing_body_amount,
    calc_debts_arr,
    debts,
    loan_balances,
    taxable_incomes,
    taxable_incomes_result,
    tax_amount,
    other_income_tax,
    income_deduction,
    dead_cross_year,
    taxable_income_over_other_year,
    atcf_over_other_income_year,
    is_parchased,
    total_parchased_loan,
    total_parchased_loan_interest,
    total_parchased_deprication,
    diff_parchased_to_now,
    old_body_life_ratio,
    old_equipment_life_ratio,
    annual_depreciation_costs,
    depreciation_length,
    book_values,
    other_incomes,
    total_effective_rents,
    gross_rate,
    noi_rate,
    printURL,
    pureCapital,
    final_asset,
    csvData,
    accumulation_income,
  }
}

/** 引数の2番目から順番に見ていって数字が出たら返す、なければfallback */
const merge = (
  fallback: number,
  ...values: (number | undefined | null | false | true)[]
): number => {
  const v = values.find((v) => {
    if (v === undefined) return false
    if (v === null) return false
    if (typeof v === 'number' && isNaN(v)) return false
    return true
  })
  if (v !== null && v !== undefined && v !== true && v !== false) return v
  return fallback
}
